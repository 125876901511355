@import "styles/globals/variables";


.block{
    overflow: hidden;
    position: relative;
}

.show{
    max-height: none !important;

    & + button svg{
        transform: rotate(90deg);
    }
}

.button{
    width: 100%;
    position: absolute;
    bottom: 0;
    line-height: 1;
    left: 0;
    background-color: var(--white);
    font-weight: 800;
    color: var(--link_color);

    &::after{
        position: absolute;
        width: 100%;
        height: 100px;
        top: -100px;
        left: 0;
        opacity: .95;
        content: "";
        background: linear-gradient(to bottom,  rgba(255, 255, 255, 0) 0%,rgba(255,255,255,1) 84%,rgba(255,255,255,1) 100%);
    }

    & svg{
        fill: var(--link_color);
        transform: rotate(-90deg);
        margin-left: .5rem;
    }
}

.content{
    width: 100%;
}


